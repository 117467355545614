body {
  background-color: black;
}
.border-gray {
  border-color: #ddd;
}

.App{
  background-color: black;
  color: white;
}
.div-with-blended-borders {
  border-top: 1px solid #00A7CD;
  border-left: 1px solid ;
  border-right: 1px solid;
  border-bottom: 1px solid #EC1B78;
  border-image: linear-gradient(to bottom, #EC1B78, #00A7CD) 1;
}
.cursor-pointer{
  cursor: pointer !important;
}
.summary-box{
  color: white;
  font-weight: 800;
}
.summary-box h4{
  color: white;
  font-weight: 800;
}
.tile-box-0{
  background-color: #00A7CD !important;
}
.tile-box-1{
  background-color: #EC1B78 !important;
}
.tile-box-2{
  background-color: #00A7CD !important;
}
.tile-box-3{
  background-color: #EC1B78 !important;
}

.logo{
  width: 100px;
  height:auto;
}

.history-item {
  cursor: pointer;
}

.history-item span {
  font-size: 14px;
}

.summary-box span {
  font-size: 14px;
}

.history-page-utility > *:not(:last-child) {
  margin-right: 10px;
}

.pagination-button {
  padding: 5px;
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #00A7CD;
}

.btn-pink {
  color: #fff !important;
  background-color: #EC1B78 !important;
  font-weight: 700 !important;
  border-radius: 7px !important;
}

.left-white{
  border-left: 1px solid white !important;
}

.btn-black{
  background-color: black !important;
  color: #EC1B78 !important;
  border: 1px solid #EC1B78 !important;
  border-radius: 10px !important;
}

.bg-dark{
  background-color: black !important;
}
.copy-alert{
  background: black !important;
  color: white !important;
  margin: 10px auto !important;
  padding: 0 !important;
  text-align: center !important;
}
.alert-danger{
  background-color: black;
  color: #EC1B78;
  margin: 10px auto !important;
  padding: 0 !important;
  text-align: center !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: #00A7CD;
}

.btn-outline-primary {
  color: #00A7CD;
  border-color: #00A7CD;
}

.btn-outline-primary:hover {
  color: #00A7CD;
  border-color: #00A7CD;
}

.input-group > .form-control:focus {
  transition: all 0.2s linear;
  border-color: #00A7CD;
  outline: 0;
  box-shadow: inset 0 0 0 1px #00A7CD;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-leading,
.form-outline .form-control:focus ~ .form-notch .form-notch-middle,
.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-color: #00A7CD;
}

.form-outline .form-control:focus ~ .form-label {
  color: #00A7CD;
}
